import React, { useState, useRef } from 'react';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { MDXProvider } from '@mdx-js/react';
import emailjs from 'emailjs-com';
import Fade from 'react-reveal/Fade';
import { graphql } from 'gatsby';
import SiteMetadata from 'src/components/SiteMetadata.jsx';
import Layout from 'src/layouts/Layout';
import Button from 'src/components/Button';
import MAHHLogo from 'src/images/mahh-logo-512.png';
import './careerTemplate.scss';

const CareerTemplate = (props) => {
  // States
  const [loading, setLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  // Error from emailjs
  const [errorServer, setErrorServer] = useState('');

  // Refs to store values
  const name = useRef(null);
  const email = useRef(null);
  const message = useRef(null);

  const jobDetail = props?.data?.contentfulJob;

  /** Reset Local State -> Error states */
  const resetErrorState = () => {
    setErrorName(false);
    setErrorEmail(false);
    setErrorMessage(false);
    setErrorServer('');
  };

  const handleSubmit = (event) => {
    // Reset state
    resetErrorState();

    // Event
    event.preventDefault();

    // Get Ref Value
    const nameValue = name.current.value;
    const emailValue = email.current.value;
    const messageValue = message.current.value;

    if (!nameValue) {
      setErrorName(true);
    }

    if (!emailValue) {
      setErrorEmail(true);
    }

    if (!messageValue) {
      setErrorMessage(true);
    }

    if (!nameValue || !emailValue || !messageValue) {
      return;
    }

    let data = {
      from_name: nameValue,
      email: emailValue,
      to_name: 'MAHH',
      message: messageValue,
    };

    // Loading
    setLoading(true);

    emailjs
      .send(
        process.env.GATSBY_SERVICE_ID,
        process.env.GATSBY_TEMPLATE_ID,
        data,
        process.env.GATSBY_USER_ID
      )
      .then(
        function (response) {
          console.log(response.status, response.text);
          // Show snackbar and turn off loading
          if (response.status === 200) {
            setLoading(false);
            setShowSnackBar(true);
            setSuccess(true);
            setTimeout(() => {
              setShowSnackBar(false);
            }, 4000);
          }
        },
        function (err) {
          console.log(err);
          setLoading(false);
          setErrorServer(JSON.stringify(err));
        }
      );
  };

  return (
    <Layout>
      <SiteMetadata
        title='Contact'
        description='MAHH Art - APEPLUS STUDIO: Bunch of creative primates producing feral digital media content based on Indonesia 🇮🇩.'
        image={MAHHLogo}
      />
      <div className='career-template-container'>
        <div className='text-center'>
          <Fade bottom>
            <h2 className='mahh-section-title-border text-5xl text-white'>
              CAREERS
            </h2>
          </Fade>
        </div>
        <div className='career-template-content-container'>
          <div className='career-template-first-content text-white'>
            <MDXProvider>
              <h3 className='font-bold text-xl text-white'>
                {jobDetail?.jobTitle}
              </h3>
              <p>{jobDetail?.employmentTypes}</p>
              <div className='career-full-description'>
                <MDXRenderer>
                  {jobDetail?.fullDescriptionText?.childMdx?.body}
                </MDXRenderer>
              </div>
            </MDXProvider>
          </div>
          <div className='career-template-second-content'>
            <form className='career-form' onSubmit={handleSubmit}>
              <div className='cp-form-wrapper'>
                <input
                  ref={name}
                  aria-label='Name'
                  name='name'
                  type='text'
                  className='career-input-form'
                  placeholder='Full Name'
                  required
                />
                {errorName && (
                  <p className='error-text text-left mb-4'>
                    Please enter your name
                  </p>
                )}
              </div>
              <div className='cp-form-wrapper'>
                <input
                  ref={email}
                  aria-label='email'
                  name='email'
                  className='career-input-form'
                  placeholder='Email Address'
                  required
                  type='email'
                />
                {errorEmail && (
                  <p className='error-text text-left mb-4'>
                    Please enter your email
                  </p>
                )}
              </div>
              <textarea
                ref={message}
                className='career-input-form mb-0'
                rows='8'
                placeholder={`Hi, I'm a ${jobDetail?.jobTitle}...`}
              />
              {errorMessage && (
                <p className='error-text text-left mb-4'>
                  Please enter your message
                </p>
              )}
              {/* Button: Success | Loading | Submit */}
              {success ? (
                <Button className='background-green-success' disabled={true}>
                  Sent{' '}
                  <span role='img' aria-label='checkmark'>
                    ✅
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  disabled={loading}
                  className={`${
                    loading
                      ? 'background-grey-ds cursor-not-allowed'
                      : 'background-red-ds'
                  }`}
                >
                  {loading ? 'Loading...' : 'APPLY NOW'}
                </Button>
              )}
              {errorServer && (
                <p className='error-text text-left mt-2'>
                  Oops server error: {errorMessage}
                </p>
              )}
            </form>
          </div>
        </div>
        {/* Snackbar */}
        <div
          className={`snackbar background-green-success ${
            showSnackBar ? 'show' : ' '
          }`}
        >
          Email sent! We will reply you soon{' '}
          <span role='img' aria-label='cool-guy'>
            😎
          </span>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Job($slug: String!) {
    contentfulJob(slug: { eq: $slug }) {
      slug
      jobTitle
      employmentTypes
      fullDescriptionText {
        fullDescriptionText
        childMdx {
          body
        }
      }
    }
  }
`;

export default CareerTemplate;
